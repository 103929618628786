import router from "@/router";

const defaultState = () => {
  return {
    session: null
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    setSession(state, payload) {
      state.session = payload;
    },
    resetState(state) {
      Object.assign(state, defaultState());
    }
  },
  actions: {
    async resetPassword({ commit, dispatch }, email) {
      commit("setLoading", true, { root: true });
      try {
        const { error } = await this._vm.$supabase.auth.api.resetPasswordForEmail(email, {
          redirectTo: `${process.env.VUE_APP_CLIENT_URL}/account/user/password/`
        });
        if (error) {
          dispatch("alert/createAuthError", error.message, { root: true });
          return;
        }
        dispatch(
          "alert/createAuthAlert",
          "Password reset submitted. An email with a confirmation link has been dispatched.",
          { root: true }
        );
      } catch (error) {
        console.error(error);
      } finally {
        commit("setLoading", false, { root: true });
      }
    },
    async signin({ commit, dispatch, state }, { email, password }) {
      commit("setLoading", true, { root: true });
      try {
        const { error, user } = await this._vm.$supabase.auth.signIn({
          email,
          password
        });
        if (error) {
          dispatch("alert/createAuthError", error.message, { root: true });
          return;
        }
        // No error throw, but no user detected so send magic link
        if (!error && !user) {
          dispatch("alert/createAuthAlert", "Check your email for the login link.", { root: true });
          state.email = state.password = null;
          return;
        }
        state.email = state.password = null;
      } catch (error) {
        console.error(error);
      } finally {
        commit("setLoading", false, { root: true });
      }
    },
    async signout({ dispatch }) {
      dispatch("alert/createAlert", "Logging out...", { root: true });
      try {
        const { error } = await this._vm.$supabase.auth.signOut();
        if (error) {
          dispatch("alert/createError", error.message, { root: true });
          console.error(error);
          // if (error.status === 401) {
          //   commit("auth/setSession", null);
          // }
          return;
        }
        dispatch("resetGlobalState", null, { root: true });
        dispatch("alert/createAlert", "Logged out.", { root: true });
      } catch (error) {
        console.error(error);
      }
    },
    async signup({ commit, dispatch, state }, { email, password, reference }) {
      commit("setLoading", true, { root: true });
      try {
        const { error } = await this._vm.$supabase.auth.signUp(
          {
            email,
            password
          },
          {
            redirectTo:
              reference === undefined
                ? `${process.env.VUE_APP_CLIENT_URL}/account/user/signin/?verified`
                : `${process.env.VUE_APP_CLIENT_URL}/account/user/signin/?verified%26reference%3D${reference}`
          }
        );
        if (error) {
          dispatch("alert/createAuthError", error.message, { root: true });
          return;
        }
        dispatch(
          "alert/createAuthAlert",
          "Account created successfully! An email with a confirmation link has been dispatched.",
          { root: true }
        );
        state.email = state.password = null;
      } catch (error) {
        console.error(error);
      } finally {
        commit("setLoading", false, { root: true });
      }
    },
    async updateUser({ commit, dispatch, state }, password) {
      commit("setLoading", true, { root: true });
      try {
        const { error } = await this._vm.$supabase.auth.api.updateUser(state.session.access_token, {
          password
        });
        if (error) {
          dispatch("alert/createAuthError", error.message, { root: true });
          return;
        }
        dispatch("alert/createAuthAlert", "Password updated.", { root: true });
        router.push({ name: "home" });
      } catch (error) {
        console.error(error);
      } finally {
        commit("setLoading", false, { root: true });
      }
    }
  },
  getters: {
    // localSession() {
    //   if (window && window.localStorage) {
    //     return window.localStorage.getItem("supabase.auth.token");
    //   }
    // }
    // userId: state => state.loading
    userId: state => state.session.user.id,
    accessToken: state => state.session.access_token,
    refreshToken: state => state.session.refresh_token
  }
};
