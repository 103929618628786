import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import account from "./account";
import dashboard from "./dashboard";
import legal from "./legal";
import page from "./page";
import widget from "./widget";

Vue.use(VueRouter);

const routes = [...account, ...dashboard, ...legal, ...widget, ...page]; // page contains catchall 404 and must be last

const router = new VueRouter({
  mode: "history",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      if (to.hash && to.hash.match(/^#access_token/) === null) {
        position.selector = to.hash;
        if (to.hash === "#how-it-works" || to.hash === "#reports" || to.hash === "#install") {
          position.offset = { y: 60 };
        }
        if (document.querySelector(to.hash)) {
          return position;
        }
        return false;
      }
      return { y: 0 };
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  let token = store.state.auth.session;
  if (window && window.localStorage) {
    // return window.localStorage.getItem("supabase.auth.token");
    token = token ?? window.localStorage.getItem("supabase.auth.token");
  }

  if (to.matched.some(record => record.meta.access === "member") && token == null) {
    next({ name: "account-user-signin" });
  } else if (to.matched.some(record => record.meta.access === "guest") && token != null) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
