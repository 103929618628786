import router from "@/router";
import Vue from "vue";

const defaultState = () => {
  return {
    widgetActive: null,
    widgets: null
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    setWidgetActive(state, payload) {
      state.widgetActive = payload;
    },
    setWidget(state, payload) {
      Vue.set(state.widgets, payload.id, payload);
    },
    setWidgetEvents(state, { id, data }) {
      Vue.set(state.widgets[id], "events", data);
    },
    setWidgets(state, payload) {
      state.widgets = payload;
    },
    setWidgetStats(state, { id, data }) {
      Vue.set(state.widgets[id], "stats", data);
    }
  },
  actions: {
    async createWidget({ commit, dispatch }, widget) {
      commit("setLoading", true, { root: true });
      const { data, error } = await this._vm.$api.post({ name: "Widget", method: "createWidget" }, widget);
      if (error !== undefined) {
        dispatch("alert/createError", error, { root: true });
        return;
      }
      commit("setWidget", data);
      commit("setWidgetActive", data.id);
      commit("user/addWidgetList", data.id, { root: true });
      commit("setLoading", false, { root: true });
      dispatch("alert/createSaved", null, { root: true });
      router.push({ name: "widget-edit", params: { id: data.id } });
    },
    async updateWidget({ commit, dispatch }, widget) {
      commit("setLoading", true, { root: true });
      const { data, error } = await this._vm.$api.post(
        {
          id: widget.id,
          name: "Widget",
          method: "updateWidget"
        },
        widget
      );
      if (error !== undefined) {
        dispatch("alert/createError", error, { root: true });
        return;
      }
      commit("setWidget", data);
      commit("setWidgetActive", data.id);
      commit("setLoading", false, { root: true });
      dispatch("alert/createSaved", null, { root: true });
      return data;
    },
    async statsByWidgetId({ commit, dispatch, rootState, state }, id) {
      if (rootState.auth.session !== null && state.widgets !== null) {
        const { data, error } = await this._vm.$api.post({ id, name: "Widget", method: "statsByWidgetId" });
        if (error !== undefined) {
          dispatch("alert/createError", error, { root: true });
          return;
        }
        commit("setWidgetStats", { id, data });
        return data;
      }
    },
    async eventsByWidgetId({ commit, dispatch, rootState, state }, id) {
      if (rootState.auth.session !== null && state.widgets !== null) {
        const { data, error } = await this._vm.$api.post({ id, name: "Widget", method: "eventsByWidgetId" });
        if (error !== undefined) {
          dispatch("alert/createError", error, { root: true });
          return;
        }
        commit("setWidgetEvents", { id, data });
      }
    }
  },
  getters: {
    widgetActive: state => {
      if (state.widgets !== null && state.widgetActive !== null) {
        return state.widgets[state.widgetActive];
      }
      return null;
    },
    widgetById: state => id => {
      if (state.widgets !== null) {
        return state.widgets[id];
      }
      return null;
    },
    widgetsByUser: (state, getters, rootState) => {
      if (state.widgets !== null && rootState.user.widgetList.length > 0) {
        return rootState.user.widgetList.map(id => state.widgets[id]);
      }
      return null;
    }
  }
};
