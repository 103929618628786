import ReviewBoostr from "@reviewboostr/boostrjs";
// import ReviewBoostr from "@reviewboostr/boostrjs-dev";
// import Boostr from "./boostr";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vue from "vue";
import VueGtag from "vue-gtag";
import VueHighlightJS from "vue-highlightjs";
import VueMeta from "vue-meta";
import API from "./api";
import App from "./App.vue";
import "./assets/style.scss";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import SupabasePlugin from "./SupabasePlugin";

Vue.config.productionTip = false;

Vue.use(ReviewBoostr);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(
  VueGtag,
  {
    enabled: String(process.env.VUE_APP_STAGE) === "production" ? true : false,
    config: {
      id: process.env.VUE_APP_GOOGLE_TAG,
      params: {
        send_page_view: false,
        cookie_flags: "max-age=7200;secure;samesite=none"
      }
    }
  },
  router
);

Vue.use(VueHighlightJS);

Vue.use(VueMeta);

Vue.use(API, {
  url: process.env.VUE_APP_API_URL
});

Vue.use(SupabasePlugin, {
  supabaseUrl: process.env.VUE_APP_SUPABASE_URL,
  supabaseKey: process.env.VUE_APP_SUPABASE_KEY
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
