const defaultState = () => {
  return {
    widgetList: []
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    addWidgetList(state, payload) {
      state.widgetList.push(payload);
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
    setWidgetList(state, payload) {
      state.widgetList = payload;
    }
  },
  actions: {
    async userWidgets({ commit, dispatch, rootState }) {
      if (rootState.auth.session !== null && rootState.widget.widgets === null) {
        const { data, error } = await this._vm.$api.post({ name: "User", method: "userWidgets" });
        if (error !== undefined) {
          dispatch("alert/createError", error, { root: true });
          return;
        }
        commit("setWidgetList", Object.keys(data));
        commit("widget/setWidgets", data, { root: true });
      }
    }
  }
};
