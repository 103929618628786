export default [
  {
    path: "/account",
    component: () => import(/* webpackChunkName: "account" */ "@/views/Account.vue"),
    children: [
      {
        path: "user/create",
        name: "account-user-create",
        component: () => import(/* webpackChunkName: "account" */ "@/components/AccountUserCreate.vue"),
        props: route => ({ reference: route.query.reference }),
        meta: { access: "guest" }
      },
      {
        path: "user/password",
        name: "account-user-password",
        component: () => import(/* webpackChunkName: "account" */ "@/components/AccountUserPassword.vue")
        // leave this open and watch for session
      },
      {
        path: "user/reset-password",
        name: "account-user-reset-password",
        component: () => import(/* webpackChunkName: "account" */ "@/components/AccountUserResetPassword.vue"),
        meta: { access: "guest" }
      },
      {
        path: "user/signin",
        name: "account-user-signin",
        component: () => import(/* webpackChunkName: "account" */ "@/components/AccountUserSignin.vue"),
        props: route => ({
          reference: route.query.reference,
          verified: route.query.verified
        })
        // leave this open and watch for session. redirects happen here
      }
    ]
  }
];
