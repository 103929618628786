import Vue from "vue";
import Vuex from "vuex";
import alert from "./alert";
import auth from "./auth";
import user from "./user";
import widget from "./widget";

Vue.use(Vuex);

const defaultState = () => {
  return {
    loading: false
  };
};

const state = defaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, defaultState());
  },
  setLoading(state, payload) {
    state.loading = payload;
  }
};

const actions = {
  resetGlobalState({ commit }) {
    commit("resetState");
    commit("alert/resetState", null, { root: true });
    commit("auth/resetState", null, { root: true });
    commit("user/resetState", null, { root: true });
    commit("widget/resetState", null, { root: true });
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    alert,
    auth,
    user,
    widget
  }
});
