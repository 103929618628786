export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "page" */ "@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import(/* webpackChunkName: "page" */ "@/components/Home.vue")
      },
      // {
      //   path: "*",
      //   name: "notFound",
      //   component: () => import(/* webpackChunkName: "page" */ "@/components/NotFound.vue")
      // }
      {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "page" */ "@/components/NotFound.vue")
      }
    ]
  }
];
