const defaultState = () => {
  return {
    fade: true,
    message: null,
    show: false,
    variant: "info"
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    setFade(state, payload) {
      state.fade = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setShow(state, payload) {
      state.show = payload;
    },
    setVariant(state, payload) {
      state.variant = payload;
    },
    resetState(state) {
      Object.assign(state, defaultState());
    }
  },
  actions: {
    createAlert({ commit }, payload) {
      commit("resetState");
      commit("setMessage", payload);
      commit("setShow", 3);
    },
    createAuthAlert({ commit }, payload) {
      commit("resetState");
      commit("setFade", false);
      commit("setMessage", payload);
      commit("setShow", true);
    },
    createAuthError({ commit }, payload) {
      commit("resetState");
      commit("setFade", false);
      commit("setMessage", payload);
      commit("setVariant", "danger");
      commit("setShow", true);
    },
    createError({ commit }, payload) {
      commit("resetState");
      commit("setFade", false); // remove?
      commit("setMessage", payload);
      commit("setVariant", "danger");
      commit("setShow", true);
    },
    createSaved({ dispatch }) {
      dispatch("createAlert", "Saved");
    }
  }
};
