export default [
  {
    path: "/legal",
    component: () => import(/* webpackChunkName: "page" */ "@/views/Legal.vue"),
    children: [
      {
        path: "terms",
        name: "legal-terms",
        component: () => import(/* webpackChunkName: "page" */ "@/components/LegalTerms.vue")
      },
      {
        path: "privacy",
        name: "legal-privacy",
        component: () => import(/* webpackChunkName: "page" */ "@/components/LegalPrivacy.vue")
      },
      {
        path: "cookies",
        name: "legal-cookies",
        component: () => import(/* webpackChunkName: "page" */ "@/components/LegalCookies.vue")
      }
    ]
  }
];
