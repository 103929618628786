<template>
  <RouterView />
</template>

<script>
export default {
  metaInfo() {
    return {
      meta: [
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: process.env.VUE_APP_CLIENT_URL + this.$route.path
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_CLIENT_NAME
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_CLIENT_URL}/img/og-image.png`
        },
        {
          property: "og:image:width",
          content: "2200"
        },
        {
          property: "og:image:height",
          content: "1152"
        },
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:site",
          content: "@reviewboostr"
        }
      ],
      title: "ReviewBoostr",
      titleTemplate: "%s - ReviewBoostr"
    };
  }
};
</script>
