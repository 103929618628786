export default [
  {
    path: "/dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: { access: "member" },
    children: [
      {
        path: "",
        name: "dashboard-overview",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/components/DashboardOverview.vue"),
        props: route => ({ active: route.query.active })
      }
    ]
  }
];
