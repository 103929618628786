export default [
  {
    path: "/widget",
    component: () => import(/* webpackChunkName: "widget" */ "@/views/Widget.vue"),
    meta: { access: "member" },
    children: [
      {
        path: "create",
        name: "widget-create",
        component: () => import(/* webpackChunkName: "widget" */ "@/components/WidgetCreate.vue")
      },
      {
        path: ":id",
        name: "widget",
        component: () => import(/* webpackChunkName: "widget" */ "@/components/Widget.vue"),
        props: route => ({ id: route.params.id })
      },
      {
        path: ":id/edit",
        name: "widget-edit",
        component: () => import(/* webpackChunkName: "widget" */ "@/components/WidgetEdit.vue"),
        props: route => ({ id: route.params.id })
      }
    ]
  }
];
