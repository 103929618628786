import { createClient } from "@supabase/supabase-js";
import store from "./store";

export default {
  install: function(Vue, options) {
    const supabase = createClient(options.supabaseUrl, options.supabaseKey, options.supabaseOptions);

    Object.defineProperties(Vue.prototype, {
      $supabase: {
        get: () => supabase
      }
    });

    supabase.auth.onAuthStateChange((event, session) => {
      // console.log("onAuthStateChange", session);
      store.commit("auth/setSession", session);
    });
  }
};
