import store from "./store";
import {} from "@supabase/supabase-js";

const createClient = url => ({
  post: async ({ id, name, method }, data) => {
    // if (store.getters["auth/accessToken"] === null)
    const response = await fetch(url, {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${store.getters["auth/accessToken"]}`,
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify({
        object: {
          id,
          name,
          method
        },
        data
      })
    });

    return await response.json();
  }
});

export default {
  install: (Vue, options) => {
    const api = createClient(options.url);

    Object.defineProperties(Vue.prototype, {
      $api: {
        get: () => api
      }
    });
  }
};
